import React, { useState, useEffect, useRef } from 'react';

function FilterBar({
  setCategoryFilter, categoriesFilterOptions, currentCategoryFilter,
  setGroupFilter, groupsFilterOptions, currentGroupFilter, onSearchQuery,
  currentItemsTypeFilter, setItemsTypeFilter, itemsTypesFilterOptions,
}) {
  const [searchValue, setSearchValue] = useState('');
  let timer = null;
  const filtersRendered = useRef(false);

  useEffect(() => {
    if (filtersRendered.current) {
      timer = setTimeout(() => { onSearchQuery(searchValue); }, 500);
    }
    filtersRendered.current = true;
  }, [searchValue]);

  const onSearch = (e) => {
    if (timer) clearTimeout(timer);

    setSearchValue(e.target.value.replace('%', ''));
  };

  return (
    <div className="table-actions">
      <div className="filters t-under-hide">
        <span className="select input-xs filter-select can-unselect">
          <input type="checkbox" />
          <div className="select-content">
            {
              categoriesFilterOptions.map(cateogry => (
                <React.Fragment key={cateogry.id}>
                  <input
                    autoComplete="off"
                    id={`webfilter_project_${cateogry.id}`}
                    type="radio"
                    checked={currentCategoryFilter.id === cateogry.id}
                  />
                  <label onClick={() => setCategoryFilter(cateogry)}>{cateogry.name}</label>
                </React.Fragment>
              ))
            }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.categories')} />
          </div>
        </span>
        <span className="select input-xs filter-select can-unselect">
          <input type="checkbox" />
          <div className="select-content">
            {
              groupsFilterOptions.map(group => (
                <React.Fragment key={group.id}>
                  <input
                    autoComplete="off"
                    id={`webfilter_project_${group.id}`}
                    type="radio"
                    checked={currentGroupFilter.id === group.id}
                  />
                  <label onClick={() => setGroupFilter(group)}>{group.name}</label>
                </React.Fragment>
              ))
            }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.groups')} />
          </div>
        </span>
        <span className="select input-xs filter-select can-unselect">
          <input type="checkbox" />
          <div className="select-content">
            {
              itemsTypesFilterOptions.map(itemsType => (
                <React.Fragment key={itemsType.id}>
                  <input
                    autoComplete="off"
                    id={`webfilter_project_${itemsType.id}`}
                    type="radio"
                    checked={currentItemsTypeFilter === itemsType.id}
                  />
                  <label onClick={() => setItemsTypeFilter(itemsType.id)}>{itemsType.name}</label>
                </React.Fragment>
              ))
            }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.items_types')} />
          </div>
        </span>
      </div>
      <div className="right-actions">
        <div className="items items-divided">
          <div className="control icon-search input-search left-icon">
            <input
              className="input-s width-2"
              id="items_search"
              placeholder={I18n.t('search')}
              type="text"
              value={searchValue}
              onChange={onSearch}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterBar;
