import React, { useState } from 'react';
import BudgetCategoryRow from './budget_category_row';

export default function BudgetRow({
  budget, selectedCategoryId, selectedBudgetId, onCategoryChange,
}) {
  const [expandedClass, setExpandedClass] = useState('');

  const onExpand = () => {
    if (expandedClass === '') {
      setExpandedClass('expanded');
    } else {
      setExpandedClass('');
    }
  };

  return (
    <React.Fragment>
      <thead className={`expandable ${expandedClass}`}>
        <tr>
          <td className="actions">
            <i className="arrow" onClick={onExpand} />
          </td>
          <td className="bold">
            {budget.code ? `${budget.code} - ${budget.name}` : budget.name}
          </td>
          <td />
          <td className="number price bold">
            {budget.notInvoiced}
          </td>
          <td className="number price bold">
            {budget.invoiced}
          </td>
          <td className="number price bold">
            {budget.committed}
          </td>
          <td className="number price bold">
            {budget.amount}
          </td>
          <td className="number price bold">
            {budget.remaining}
          </td>
        </tr>
      </thead>
      <tbody className="expandable">
        {budget.categories.map(category => (
          <BudgetCategoryRow
            category={category}
            key={category.id}
            selectedBudgetId={selectedBudgetId}
            selectedCategoryId={selectedCategoryId}
            onCategoryChange={onCategoryChange}
          />
        ))}
      </tbody>
    </React.Fragment>
  );
}
