import React, { useState, useEffect, useRef } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import SupplierItemsModalService from '../../../services/supplier_items_modal_service';
import SupplierItemModel from '../../../models/supplier_item_model';
import HeaderModel from '../../../models/header_model';
import FilterBar from './supplier_items_modal/filter_bar';
import MobileFilterBar from './supplier_items_modal/mobile_filter_bar';
import Modal from '../../shared/modal';
import TableHead from './supplier_items_modal/table_head';
import SupplierItemRow from './supplier_items_modal/supplier_item_row';
import Loader from '../../loader';
import * as Swipe from '../../shared/swipe';

function SupplierItemsModal({
  purchaseOrderSupplierId, onToggleSupplierItemModal, addItemsToPoItemList, purchaseOrderCurrency,
}) {
  const pageRendered = useRef(false);
  const [isMobileFilterBarOpen, setIsMobileFilterBarOpen] = useState(false);
  const [state, setState] = useState({
    supplierItems: [],
    nextPage: null,
    categoriesFilterOptions: [],
    currentCategoryFilter: {},
    groupsFilterOptions: [],
    currentGroupFilter: {},
    currentItemsTypeFilter: (Number.isNaN(purchaseOrderSupplierId) ? null : 'supplier_items'),
    allSelected: false,
    selectedItems: [],
    isLoading: false,
    sortHeader: null,
    searchQuery: '',
    headers: [
      { name: 'item_number', translation: 'supplier_items.item_number', class: 'pointer' },
      { name: 'product_number', translation: 'supplier_items.product_number', class: 'pointer' },
      { name: 'global_id', translation: 'supplier_items.global_id', class: 'pointer' },
      { name: 'description', translation: 'supplier_items.description', class: 'pointer' },
      { name: 'unit_measure', translation: 'supplier_items.unit_measure', class: 'pointer' },
      { name: 'moq', translation: 'supplier_items.moq', class: 'number pointer' },
      { name: 'gross_price', translation: 'supplier_items.gross_price', class: 'number price pointer' },
      { name: 'discount', translation: 'supplier_items.discount', class: 'number pointer' },
      { name: 'net_price', translation: 'supplier_items.net_price', class: 'number price pointer' },
      { name: 'currency', translation: 'supplier_items.currency', class: 'pointer' },
    ].map(header => new HeaderModel(header)),
  });

  const itemsTypesFilterOptions = [
    { id: 'supplier_items', name: I18n.t('supplier_items.supplier_items') },
    { id: 'global_products', name: I18n.t('supplier_items.global_products') },
  ];

  useEffect(() => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    SupplierItemsModalService.index(urlQuery())
      .then(({
        supplier_items: supplierItems,
        possible_filters: {
          categories, groups,
        },
        next_page: nextPage,
      }) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: supplierItems.map(item => new SupplierItemModel(item)),
          categoriesFilterOptions: categories,
          groupsFilterOptions: groups,
          nextPage,
          isLoading: false,
        }));
        Swipe.triggerSwipeEvent();
      });
    pageRendered.current = true;
  }, []);

  useEffect(() => {
    if (pageRendered.current) {
      updateItemsList();
    }
  }, [state.currentGroupFilter, state.currentCategoryFilter, state.currentItemsTypeFilter, state.searchQuery, state.headers]);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !!state.nextPage,
    onLoadMore: () => updateItemsList(true),
  });

  const onSort = (sortHeader) => {
    const { headers } = state;
    const newHeaders = headers.map((header) => {
      if (header.name === sortHeader.name) {
        return header.setSortDirection();
      }
      return header.clean();
    });

    setState(prevState => ({
      ...prevState,
      headers: newHeaders,
      sortHeader,
      nextPage: null,
    }));
  };

  const onChangeBulkAction = (supplierItemId) => {
    const { supplierItems, selectedItems } = state;
    const clickedItem = supplierItems.find(item => item.id === supplierItemId);
    const alreadySelectedItem = selectedItems.find(({ id }) => id === supplierItemId);
    const newSupplierItems = alreadySelectedItem ? selectedItems.filter(({ id }) => id !== supplierItemId) : [...selectedItems, clickedItem];
    setState(prevState => ({ ...prevState, selectedItems: newSupplierItems }));
  };

  const onSelectAllBulkAction = () => {
    const { allSelected, supplierItems, selectedItems } = state;
    const newSelectedItems = allSelected ? [] : [...new Set([...selectedItems, ...supplierItems])];
    setState(prevState => ({
      ...prevState,
      selectedItems: newSelectedItems,
      allSelected: !allSelected,
    }));
  };

  const onSearchQuery = (value) => {
    setState(prevState => ({ ...prevState, searchQuery: value, nextPage: null }));
  };

  async function updateItemsList(extendCollection = false) {
    const { isLoading } = state;
    if (isLoading) return;
    setState(prevState => ({ ...prevState, isLoading: true }));

    SupplierItemsModalService.index(urlQuery())
      .then(({ supplier_items: items, next_page: nextPage }) => {
        const { supplierItems } = state;
        const newItems = items.map(item => new SupplierItemModel(item));
        setState(prevState => ({
          ...prevState,
          supplierItems: extendCollection ? supplierItems.concat(newItems) : newItems,
          isLoading: false,
          nextPage,
        }));
      });
  }

  // Function to prepare url for supplier items.
  // Filters (category, groups and supplier) are made to support multiselect in the future (like in most other views that don't yet support this)
  const urlQuery = () => {
    const {
      nextPage, currentItemsTypeFilter, currentCategoryFilter, currentGroupFilter, sortHeader, searchQuery,
    } = state;
    const { name: orderBy, sortDirection: orderType } = sortHeader || {};

    const queries = [
      Object.keys(currentCategoryFilter).length ? `supplier_item_category_id[]=${currentCategoryFilter.id}` : '',
      Object.keys(currentGroupFilter).length ? `supplier_item_group_id[]=${currentGroupFilter.id}` : '',
      purchaseOrderSupplierId && currentItemsTypeFilter == 'supplier_items' ? `supplier_id[]=${purchaseOrderSupplierId}` : '',
      orderBy ? `sort[by]=${orderBy}&sort[type]=${orderType}` : '',
      nextPage ? `page=${nextPage}` : 'page=1',
      currentItemsTypeFilter ? `view=${currentItemsTypeFilter}` : '',
      searchQuery ? `query=${searchQuery}` : '',
    ];

    return `?${queries.filter(q => q).join('&')}`;
  };

  const setCategoryFilter = (category) => {
    const { currentCategoryFilter } = state;
    if (currentCategoryFilter.id === category.id) {
      setState(prevState => ({ ...prevState, currentCategoryFilter: {}, nextPage: null }));
    } else {
      setState(prevState => ({ ...prevState, currentCategoryFilter: category, nextPage: null }));
    }
  };

  const setGroupFilter = (group) => {
    const { currentGroupFilter } = state;
    if (currentGroupFilter.id === group.id) {
      setState(prevState => ({ ...prevState, currentGroupFilter: {}, nextPage: null }));
    } else {
      setState(prevState => ({ ...prevState, currentGroupFilter: group, nextPage: null }));
    }
  };

  const setItemsTypeFilter = (itemsType) => {
    const { currentItemsTypeFilter } = state;
    if (currentItemsTypeFilter === itemsType) {
      setState(prevState => ({ ...prevState, currentItemsTypeFilter: '', nextPage: null }));
    } else {
      setState(prevState => ({ ...prevState, currentItemsTypeFilter: itemsType, nextPage: null }));
    }
  };

  // You can add supplier items to items list on PO
  const addItems = () => {
    const { selectedItems } = state;

    addItemsToPoItemList(selectedItems);
  };

  // Renders below are needed for mobile view and mobile filters
  const _renderInputs = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <input id={`${prefix}_column_${i}`} name={`${prefix}_table`} key={`${prefix}input_column_${i}`} defaultChecked={i === 1} type="radio" readOnly />
    ))
  );

  const _renderLabels = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <label htmlFor={`${prefix}_column_${i}`} key={`${prefix}label_column_${i}`} />
    ))
  );

  const _renderDots = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="dot" key={`${prefix}dot_column_${i}`} />
    ))
  );

  const {
    categoriesFilterOptions, currentCategoryFilter, allSelected,
    groupsFilterOptions, currentGroupFilter, headers, supplierItems, isLoading,
    selectedItems, currentItemsTypeFilter, nextPage,
  } = state;

  const dotsNumber = headers.length;
  const htmlClasses = isLoading ? ' has-loading' : '';

  return (
    <div className="modal sub-modal modal-table active">
      <div className="modal-wrapper">
        <div className="modal-window window window-form">
          <div className="window-header modal-header window-header-auto window-header-blue">
            <div className="window-header-wrapper blue" />
            {
              // We are checking here if all the selected items have the same currency as PO
              // If not we are showing warning because pirces set up for items are in specific currency
              // And we do not store currency of an item on items list on PO
              // So if item with A currency is added to the item list of PO with currency B
              // The price of the newly added item will be in currency A which is incorrect
              selectedItems.some(item => item.currency !== purchaseOrderCurrency)
                ? (
                  <div className="toast error toast-light">
                    <i className="icon-warning" />
                    {I18n.t('purchase_orders.form.items.different_currency')}
                  </div>
                ) : null
            }
            <FilterBar
              setCategoryFilter={setCategoryFilter}
              categoriesFilterOptions={categoriesFilterOptions}
              currentCategoryFilter={currentCategoryFilter}
              setGroupFilter={setGroupFilter}
              groupsFilterOptions={groupsFilterOptions}
              currentGroupFilter={currentGroupFilter}
              itemsTypesFilterOptions={itemsTypesFilterOptions}
              currentItemsTypeFilter={currentItemsTypeFilter}
              setItemsTypeFilter={setItemsTypeFilter}
              onSearchQuery={onSearchQuery}
            />
          </div>
          <div className={`window-content supplier-items ${htmlClasses}`}>
            <div className="table-fluid table-column-9">
              <Loader />
              {_renderInputs(dotsNumber)}
              <div className="table-header with-filters">
                <div className="mobile-filters">
                  <Modal>
                    <MobileFilterBar
                      setCategoryFilter={setCategoryFilter}
                      categoriesFilterOptions={categoriesFilterOptions}
                      currentCategoryFilter={currentCategoryFilter}
                      setGroupFilter={setGroupFilter}
                      groupsFilterOptions={groupsFilterOptions}
                      currentGroupFilter={currentGroupFilter}
                      itemsTypesFilterOptions={itemsTypesFilterOptions}
                      currentItemsTypeFilter={currentItemsTypeFilter}
                      setItemsTypeFilter={setItemsTypeFilter}
                      isMobileFilterBarOpen={isMobileFilterBarOpen}
                      onToggleMobileFilterBar={() => setIsMobileFilterBarOpen(!isMobileFilterBarOpen)}
                      onSearchQuery={onSearchQuery}
                    />
                  </Modal>
                  <label onClick={() => setIsMobileFilterBarOpen(!isMobileFilterBarOpen)}>
                    <i className="icon-filter_list" />
                  </label>
                </div>
                <div className="table-nav">
                  {_renderLabels(dotsNumber)}
                  {_renderDots(dotsNumber)}
                </div>
              </div>
              <table>
                <TableHead
                  headers={headers}
                  onSelectAllBulkAction={onSelectAllBulkAction}
                  allSelected={allSelected}
                  onSort={onSort}
                />
                <tbody>
                  {
                    supplierItems.map(supplierItem => (
                      <SupplierItemRow
                        key={supplierItem.id}
                        supplierItem={supplierItem}
                        selectedItems={selectedItems}
                        onChangeBulkAction={onChangeBulkAction}
                      />
                    ))
                  }
                  {/*
                    Element below is needed for react-infinite-scroll-hook - when this element is visible we get another batch of items.
                    In dotsNumber we have number of headers, we need this to show that we are loading items in the center of the table
                  */}
                  {(isLoading || !!nextPage) && (
                    <tr ref={sentryRef}>
                      <td colSpan={dotsNumber + 1} className="text-center">Loading</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="window-footer modal-footer">
            <div className="items items-end center">
              <label className="button inverse button-mute hide-on-complete" onClick={onToggleSupplierItemModal}>
                {I18n.t('commons.actions.cancel')}
              </label>
              <a
                className=" button button-primary hide-on-complete"
                onClick={addItems}
              >
                {I18n.t('commons.actions.add_select')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onToggleSupplierItemModal} />
    </div>
  );
}

export default SupplierItemsModal;
