import uuid from 'uuid/v4';

class Invitation {
  constructor() {
    this.uuid = uuid();
    this.email = '';
    this.errors = '';
  }
}

export default Invitation;
