import React from 'react';
import PropTypes from 'prop-types';
import BigDecimal from 'js-big-decimal';
import NumberField from '@/components/uiElements/inputs/NumberField';
import { toNumber } from '@/components/shared/number_formatter';
import ItemError from './item_error';
import DragItemHandle from './drag_item_handle';
import Receive from './receive';
import NumberConverter from '../../shared/number_converter';
import Textarea from '../../shared/textarea';
import SupplierItemsModalService from '../../../services/supplier_items_modal_service';
import SupplierItemModel from '../../../models/supplier_item_model';

class OrderItem extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    uuid: PropTypes.string,
    quantity: PropTypes.number,
    unit_measure_quantity: PropTypes.number,
    unit_measure_price: PropTypes.number,
    price: PropTypes.number,
    category_id: PropTypes.number,
    unit: PropTypes.string,
    sku: PropTypes.string,
    received: PropTypes.bool,
    received_quantity: PropTypes.number,
    _destroy: PropTypes.number,
    mounted: PropTypes.bool,
    currency: PropTypes.string,
    supplier_item_id: PropTypes.number,
    supplierId: PropTypes.number,
    numberFormat: PropTypes.object,
  }

  constructor(props) {
    super(props);

    const quantity = toNumber(props.item.quantity == null ? '' : props.item.quantity === '' ? props.item.quantity : Number(props.item.quantity).toFixed(2), props.numberFormat);
    const price = props.item.price == 0 ? '' : Number(props.item.price);
    const unitMeasurePrice = props.item.unit_measure_price || props.item.unitMeasurePrice;
    const unitMeasureQuantity = props.item.unit_measure_quantity || props.item.unitMeasurePrice;
    this.state = {
      id: props.item.id,
      uuid: props.item.uuid,
      name: props.item.name || '',
      price,
      displayPrice: price,
      quantity,
      displayQuantity: quantity,
      unitMeasureQuantity,
      displayUnitMeasureQuantity: unitMeasureQuantity,
      unitMeasurePrice,
      displayUnitMeasurePrice: unitMeasurePrice,
      category_id: props.item.category_id,
      unit: props.item.unit || '',
      sku: props.item.sku || '',
      received: props.item.received || '',
      receivedQuantity: props.item.received_quantity || '',
      pass: props.item.pass,
      fail: props.item.fail,
      total: 0,
      displayTotal: 0,
      totalUnit: 0.0,
      displayTotalUnit: 0.0,
      additional: 0,
      _destroy: 0,
      mounted: props.item.mounted ? props.item.mounted : false,
      supplierItems: [],
      e_type: 'item',
      position: props.item.position,
      stateCategories: props.categories,
      focus: props.item.focus || false,
      currency: props.item.currency,
    };
  }

  componentDidMount() {
    this.setTotalPrice(true);
  }

  componentDidUpdate(prevProps) {
    const { categories, calculationMethod } = this.props;
    if (prevProps.categories !== categories) {
      this.updateStateCategories(categories);
    }
    if (prevProps.calculationMethod !== calculationMethod) {
      this.setTotalPrice(true);
    }
  }

  onChangeItemNumber = (e) => {
    const { purchaseOrderSupplierId } = this.props;

    if (e.target.value !== '') {
      const finalQuery = `?by_number=${e.target.value}${purchaseOrderSupplierId ? `&supplier_id[]=${purchaseOrderSupplierId}` : ''}`;
      SupplierItemsModalService.index(finalQuery)
        .then(({ supplier_items: items }) => {
          this.setState({
            supplierItems: items.map(item => new SupplierItemModel(item)),
          });
        });
    } else {
      this.setState({ supplierItems: [] });
    }

    this.setState({
      [e.target.name]: e.target.value,
    }, () => { this.setTotalPrice(true); });
  }

  onClick = (item) => {
    const { numberFormat } = this.props;
    const grossPrice = toNumber(item.grossPrice, numberFormat);
    const discount = toNumber(item.discount, numberFormat);
    const price = (grossPrice * (100 - discount)) / 100;
    const quantity = toNumber(item.minimumOrderQuantity, numberFormat);
    this.setState({
      sku: item.number || item.productNumber,
      name: item.description,
      price,
      displayPrice: price,
      quantity,
      displayQuantity: quantity,
      unit: item.unitMeasure,
    }, () => { this.setTotalPrice(true); });
  }

  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => { this.setTotalPrice(true); });
  }

  onCategoryChange = async (value) => {
    await this.onChange({ target: { name: 'category_id', value } });
    // await this.escapeEdit();
  }

  onNumberChange = (values, name) => {
    const { formattedValue } = values;
    let { value } = values;
    const { state } = this;
    if (value === '' || value === undefined || value === null) {
      value = '';
    } else {
      value = NumberConverter(value) || state[name];
    }

    // Because of how NumericField from React is working and that we change other input values we are losing position of the cursor
    // NumericField returns value (number) and formattedValue (string) and if we display value (number) formatting and position of the cursor is lost
    // Before we stored position of the cursor because normal inputs returns event on onChange event
    // So we store and show the user formatted value in different attribute and we keep the calc logic as before in base attribute (that was before)
    this.setState(
      {
        [name]: value,
        [`display${name[0].toUpperCase() + name.slice(1)}`]: formattedValue, // Because of the above here we need to format name of the attribute from 'name' to 'displayName'
      }, () => {
        if (value != '-') this.setTotalPrice(true);
      },
    );
  }

  onUnitMeasurePriceChange = (values) => {
    const { formattedValue } = values;
    let { value } = values;
    const { state } = this;
    if (value === '') {
      value = undefined;
    } else {
      value = NumberConverter(value) || state.price;
    }

    this.setState({
      price: value,
      displayPrice: formattedValue,
    }, () => {
      if (value == '-') { return; }
      const {
        state: {
          unitMeasurePrice, unitMeasureQuantity, price, quantity,
        }, props: { handleEdit, arrayIndex },
      } = this;

      const calculatedValues = {
        total: this.calculateTotal(price, quantity),
      };
      calculatedValues.displayTotal = calculatedValues.total;

      if ((unitMeasureQuantity !== '' && unitMeasureQuantity !== undefined && unitMeasureQuantity != 0)) {
        calculatedValues.unitMeasurePrice = Number(BigDecimal.divide(price, unitMeasureQuantity));
        calculatedValues.displayUnitMeasurePrice = calculatedValues.unitMeasurePrice;
      } else if ((unitMeasurePrice !== '' && unitMeasurePrice !== undefined && unitMeasurePrice != 0)) {
        calculatedValues.unitMeasureQuantity = Number(BigDecimal.divide(price, unitMeasurePrice));
        calculatedValues.displayUnitMeasureQuantity = calculatedValues.unitMeasureQuantity;
      }

      this.setState(calculatedValues, () => { handleEdit(this.state, arrayIndex, true); });
    });
  }

  onChangeTotal = (values) => {
    const { formattedValue } = values;
    let { value } = values;
    const { state: { price, quantity }, props: { handleEdit, arrayIndex, numberFormat } } = this;
    if (value !== '') {
      value = NumberConverter(value);
    }

    const newPrice = toNumber(price !== '' && quantity != 0 && value != '-' ? Number(BigDecimal.divide(value, quantity)) : value, numberFormat);
    const newQuantity = toNumber(quantity || 1, numberFormat);
    this.setState({
      total: value,
      displayTotal: formattedValue,
      quantity: newQuantity,
      displayQuantity: newQuantity,
      price: newPrice,
      displayPrice: newPrice,
    }, () => { handleEdit(this.state, arrayIndex, true); });
  }

  onChangeTotalUnit = (values) => {
    const { state: { quantity, price, unitMeasureQuantity }, props: { handleEdit, arrayIndex } } = this;
    const { formattedValue } = values;
    let { value } = values;
    if (value !== '') {
      value = NumberConverter(value);
    }
    if (value == '-') {
      this.setState({
        totalUnit: value,
        displayTotalUnit: formattedValue,
      }, () => { handleEdit(this.state, arrayIndex, true); });
      return;
    }

    const newUnitMeasureQuantity = quantity != 0 ? Number(BigDecimal.divide(value, quantity)) : value;
    const newUnitMeasurePrice = unitMeasureQuantity != 0 && unitMeasureQuantity != undefined ? Number(BigDecimal.divide(price, unitMeasureQuantity)) : price;

    this.setState({
      totalUnit: value,
      displayTotalUnit: formattedValue,
      unitMeasureQuantity: newUnitMeasureQuantity,
      displayUnitMeasureQuantity: newUnitMeasureQuantity,
      unitMeasurePrice: newUnitMeasurePrice,
      displayUnitMeasurePrice: newUnitMeasurePrice,
    }, () => { handleEdit(this.state, arrayIndex, true); });
  }

  onRemoveItem = () => {
    const { handleDeleteClick, itemsCount } = this.props;
    const { id, uuid, position } = this.state;
    if (position === 0 && itemsCount == 1) {
      this.setState({
        id,
        uuid,
        position,
        name: '',
        price: '',
        quantity: '',
        category_id: '',
        _destroy: 0,
        mounted: true,
        unit: '',
        sku: '',
        received: '',
        receivedQuantity: '',
        e_type: 'item',
      }, () => { handleDeleteClick(this.state, uuid); });
    } else {
      this.setState({ _destroy: '1' }, () => { handleDeleteClick(this.state, uuid); });
    }
  }

  calculateTotal = (price, quantity) => {
    let total = '';
    if ((price !== '' && price !== undefined) && (quantity !== '' && quantity !== undefined)) {
      total = Number(BigDecimal.multiply(price, quantity));
    }
    return total;
  }

  setTotalPrice = (calculate) => {
    const {
      state: {
        price, quantity, unitMeasureQuantity, unitMeasurePrice, total, totalUnit,
      }, props: { calculationMethod, handleEdit, arrayIndex },
    } = this;
    let newTotal = '';
    if ((price !== '' && price !== undefined) && (quantity !== '' && quantity !== undefined)) {
      newTotal = Number(BigDecimal.multiply(price, quantity));
    }
    const calculatedValues = { total: newTotal, displayTotal: newTotal };
    let changed = false;

    if (calculationMethod === 'unit_measure') {
      if (unitMeasureQuantity) {
        if (unitMeasurePrice) {
          calculatedValues.price = Number(BigDecimal.multiply(unitMeasureQuantity, unitMeasurePrice));
          calculatedValues.displayPrice = calculatedValues.price;
          changed = calculatedValues.price !== price;
        } else {
          calculatedValues.unitMeasurePrice = Number(BigDecimal.divide(price, unitMeasureQuantity));
          calculatedValues.displayUnitMeasurePrice = calculatedValues.unitMeasurePrice;
          changed = calculatedValues.unitMeasurePrice !== unitMeasurePrice;
        }
      }
      calculatedValues.totalUnit = Number(BigDecimal.multiply(unitMeasureQuantity, quantity));
      calculatedValues.displayTotalUnit = calculatedValues.totalUnit;
      changed = changed || calculatedValues.totalUnit !== totalUnit;
      calculatedValues.total = this.calculateTotal(calculatedValues.price || price, quantity);
      calculatedValues.displayTotal = calculatedValues.total;
      changed = changed || calculatedValues.total !== total;
    } else {
      calculatedValues.total = this.calculateTotal(price, quantity);
      calculatedValues.displayTotal = calculatedValues.total;
      changed = calculatedValues.total !== total;
    }

    this.setState(calculatedValues, () => { handleEdit(this.state, arrayIndex, calculate && changed); });
  }

  getUncategorizedOption = () => {
    const {
      state: { category_id },
      props: { showUncategorized, isDraft },
    } = this;
    const wasUncategorized = !isDraft && !category_id;
    if (wasUncategorized || showUncategorized) {
      return (
        <label
          key="nil_label"
          onClick={() => this.onChange({ target: { name: 'category_id', value: null } })}
          className={category_id === null || category_id === '' ? 'checked' : ''}
        >
          {I18n.t('purchase_orders.form.items.uncategorized')}
        </label>
      );
    }
    if (!category_id) {
      return <div className="placeholder">---please select---</div>;
    }
    return null;
  }

  onInputValue = async ({ target: { value } }) => {
    const { categories } = this.props;
    if (this.timer) clearTimeout(this.timer);

    this.setState({ inputValue: value }, () => {
      this.timer = setTimeout(() => {
        this.setState({
          stateCategories: categories.filter(
            entity => (
              `${entity.number} - ${entity.name}`.toLowerCase().includes(value.toLowerCase())
            ),
          ),
        });
      }, 300);
    });
  };

  updateStateCategories = (categories) => {
    this.setState({ stateCategories: categories });
    this.onChange({ target: { name: 'category_id', value: null } });
  }

  escapeValueEdit = (e) => {
    const { addItem } = this.props;
    if (e.key === 'Enter' && addItem()) {
      e.target.blur();
    }
  }

  // escapeEdit = async () => {
  //   const { saveDraft } = this.props;
  //   await saveDraft();
  // }

  updateReceive = (receive) => {
    const { handleEdit, arrayIndex } = this.props;
    this.setState({
      received: receive.state.received,
      receivedQuantity: receive.state.receivedQuantity,
    }, () => { handleEdit(this.state, arrayIndex, true); });
  }

  countTotalCost = () => {
    const { state: { total }, props: { rate, additional } } = this;
    if (total == '-') return additional;
    const totalDecimal = new BigDecimal(total);
    const rateDecimal = new BigDecimal(rate);
    const additionalDecimal = new BigDecimal(additional);

    return Number(totalDecimal.multiply(rateDecimal).add(additionalDecimal).round(2).getValue());
  }

  assignDefaultFocus = (input) => {
    const { focus } = this.state;
    if (this.isDisabled()) return;
    const inputToByFocused = input;
    inputToByFocused.closest('.select').firstElementChild.checked = true;
    if (focus) inputToByFocused.focus();
    this.setState({ mounted: false });
  }

  isDisabled = () => {
    const { state: { id }, props: { isDraft } } = this;
    return isDraft && !id;
  }

  render() {
    const {
      state: {
        _destroy, mounted, sku, name, category_id, unit, total, currency, supplierItems, inputValue,
        stateCategories, displayQuantity, displayUnitMeasureQuantity,
        displayUnitMeasurePrice, displayPrice, displayTotalUnit, displayTotal,
      },
      props: {
        item: { errors }, item: propsItem,
        editable, companyCurrency, showDelivery,
        receiveEditable, calculationMethod, numberFormat,
      },
    } = this;
    const htmlClasses = this.isDisabled() ? 'disabled' : '';
    const itemNumberDisabled = propsItem.supplier_id == null && propsItem.supplier_item_id != null;
    const { thousands_separator: thousandSeparator, decimal_mark: separator, placeholder } = numberFormat;
    const amountFormat = { delimiter: thousandSeparator, separator, precision: 2 };

    return (
      <tr
        className={htmlClasses}
        style={_destroy == 1 ? { display: 'none' } : {}}
      >
        <td
          className={`no ${errors && errors.sku ? 'has-error' : ''}`}
          data-th={I18n.t('purchase_orders.form.items.sku')}
        >
          <span>
            <span className={`select input-table auto-position auto-content-width ${itemNumberDisabled ? 'disabled' : ''}`}>
              <input type="checkbox" />
              <div className={`control ${supplierItems.length == 0 ? 'empty' : null}`}>
                <input
                  ref={input => input && mounted && this.assignDefaultFocus(input)}
                  type="text"
                  value={sku}
                  name="sku"
                  placeholder="0"
                  onChange={this.onChangeItemNumber}
                  tabIndex="-1"
                  onFocus={(e) => { e.target.select(); }}
                  disabled={itemNumberDisabled}
                />
                {errors && errors.sku ? <ItemError value={errors.sku} /> : ''}
              </div>
              <div className="select-content">
                {
                  supplierItems.map(item => (
                    <label onMouseDown={() => this.onClick(item)}>{item.displayName()}</label>
                  ))
                }
                {
                  sku === ''
                    ? (
                      <div className="placeholder" data-placeholder="0" />
                    )
                    : (
                      <div className="placeholder value" data-placeholder={sku} />
                    )
                }
              </div>
            </span>
          </span>
        </td>
        <td
          className={(errors && errors.name ? 'has-error desc' : 'desc')}
          data-th={I18n.t('purchase_orders.form.items.description')}
        >
          <span>
            <Textarea
              value={name}
              disabled={!editable}
              name="name"
              placeholder={I18n.t('purchase_orders.form.items.item_name')}
              onChange={this.onChange}
              // onBlur={this.escapeEdit}
              className="input-table"
              extraHeight={1}
            />
            {errors && errors.name ? <ItemError value={errors.name} /> : ''}
          </span>
        </td>
        <td
          className={
            (errors && (errors.category_id || errors.category_id)
              ? 'has-error category'
              : 'category')
          }
          data-th={I18n.t('purchase_orders.form.items.budget_category')}
        >
          <span>
            <div className="select auto-position auto-content-width input-table input-r">
              <input type="checkbox" />
              <div className="control">
                <input
                  placeholder={I18n.t('commons.actions.enter')}
                  tabIndex="-1"
                  type="text"
                  onChange={this.onInputValue}
                  value={inputValue}
                />
              </div>
              <div className="select-content">
                {this.getUncategorizedOption()}
                {
                  stateCategories.map(category => (
                    <label
                      key={`${category.id}_lable`}
                      onClick={async () => { await this.onCategoryChange(category.id); }}
                      className={category.id === category_id ? 'checked' : ''}
                    >
                      {category.code ? `${category.code} - ${category.name}` : category.name}
                    </label>
                  ))
                }
              </div>
              <span />
            </div>
            {
              errors && errors.category_id
                ? <ItemError value={errors.category_id} />
                : ''
            }
          </span>
        </td>
        <td
          className={`number ${errors && errors.quantity ? 'has-error' : ''}`}
          data-th={I18n.t('purchase_orders.form.items.quantity')}
        >
          <span name="quantity">
            <NumberField
              value={displayQuantity}
              onChange={values => this.onNumberChange(values, 'quantity')}
              table
              decimal
              decimalSeparator={separator}
              thousandSeparator={thousandSeparator}
              name="quantity"
              disabled={!editable}
              placeholder={placeholder}
              getValues
              allowNegative={false}
            />
            {
              errors && errors.quantity
                ? <ItemError value={errors.quantity} />
                : ''
            }
          </span>
        </td>
        <td
          className={`number price ${errors && errors.unit ? 'has-error' : ''}`}
          data-th={I18n.t('purchase_orders.form.items.unit')}
        >
          <span>
            <Textarea
              value={unit}
              name="unit"
              isNumber
              placeholder={I18n.t('purchase_orders.form.items.unit')}
              onChange={this.onChange}
              disabled={!editable}
              // onBlur={this.escapeEdit}
              className="input-table"
            />
            {errors && errors.unit ? <ItemError value={errors.unit} /> : ''}
          </span>
        </td>
        {calculationMethod === 'unit_measure' ? (
          <>
            <td
              className={`number ${errors && errors.unit_measure_quantity ? 'has-error' : ''}`}
              data-th={I18n.t('purchase_orders.form.items.unit_measure_quantity')}
            >
              <span name="unitMeasureQuantity">
                <NumberField
                  value={displayUnitMeasureQuantity}
                  onChange={values => this.onNumberChange(values, 'unitMeasureQuantity')}
                  table
                  decimal
                  decimalSeparator={separator}
                  thousandSeparator={thousandSeparator}
                  name="unitMeasureQuantity"
                  disabled={!editable}
                  placeholder={placeholder}
                  getValues
                />
                {
                  errors && errors.unit_measure_quantity
                    ? <ItemError value={errors.unit_measure_quantity} />
                    : ''
                }
              </span>
            </td>
            <td
              className={`number ${errors && errors.unit_measure_price ? 'has-error' : ''}`}
              data-th={I18n.t('purchase_orders.form.items.unit_measure_price')}
            >
              <span name="unitMeasurePrice">
                <NumberField
                  value={displayUnitMeasurePrice}
                  onChange={values => this.onNumberChange(values, 'unitMeasurePrice')}
                  table
                  decimal
                  decimalSeparator={separator}
                  thousandSeparator={thousandSeparator}
                  name="unitMeasurePrice"
                  disabled={!editable}
                  placeholder={placeholder}
                  getValues
                />
                {
                  errors && errors.unit_measure_price
                    ? <ItemError value={errors.unit_measure_price} />
                    : ''
                }
              </span>
            </td>
          </>
        ) : null}
        <td
          className={`number price ${errors && errors.price_cents
            ? 'has-error'
            : ''}`}
          data-th={I18n.t('purchase_orders.form.items.unit_price')}
        >
          <span name="unit_price">
            <NumberField
              value={displayPrice}
              onChange={values => (calculationMethod === 'unit_measure' ? this.onUnitMeasurePriceChange(values) : this.onNumberChange(values, 'price'))}
              table
              decimal
              decimalSeparator={separator}
              thousandSeparator={thousandSeparator}
              name="price"
              disabled={!editable}
              placeholder={placeholder}
              getValues
            />
            {
              errors && errors.price_cents
                ? <ItemError value={errors.price_cents} className="text-right" />
                : ''
            }
          </span>
        </td>
        {calculationMethod === 'unit_measure' ? (
          <td
            className="number price"
            data-th={I18n.t('purchase_orders.form.items.total_unit')}
          >
            <span>
              <NumberField
                value={displayTotalUnit}
                onChange={values => this.onChangeTotalUnit(values)}
                table
                decimal
                decimalSeparator={separator}
                thousandSeparator={thousandSeparator}
                name="totalUnit"
                disabled={!editable}
                placeholder={placeholder}
                getValues
              />
            </span>
          </td>
        ) : null}
        <td
          className="number price"
          data-th={I18n.t('purchase_orders.form.items.value')}
        >
          <span>
            <NumberField
              value={displayTotal}
              onChange={values => this.onChangeTotal(values)}
              table
              decimal
              decimalSeparator={separator}
              thousandSeparator={thousandSeparator}
              name="total"
              disabled={!editable}
              placeholder={placeholder}
              getValues
            />
          </span>
        </td>
        <td
          className="number price"
          data-th={I18n.t('purchase_orders.form.items.value')}
        >
          <span>
            {
              I18n.toNumber(companyCurrency != currency
                ? this.countTotalCost()
                : total, amountFormat)
            }
          </span>
        </td>

        {showDelivery
          ? (
            <Receive
              updateReceive={this.updateReceive}
              item={this.state}
              propsItem={propsItem}
              receiveEditable={receiveEditable}
              editable={editable}
              numberFormat={numberFormat}
            >
              <td className="colored actions set">
                <span>
                  {editable ? (
                    <a className="on-hover remove" onClick={this.onRemoveItem}>
                      <i className="icon-delete_forever" />
                      <span className="mobile-inline">Remove item</span>
                    </a>
                  ) : ''}
                </span>
                <DragItemHandle />
              </td>
            </Receive>
          ) : (
            <td className="actions set">
              <span>
                {editable ? (
                  <a className="on-hover remove" onClick={this.onRemoveItem}>
                    <i className="icon-delete_forever" />
                    <span className="mobile-inline">Remove item</span>
                  </a>
                ) : ''}
              </span>
              <DragItemHandle />
            </td>
          )}
      </tr>
    );
  }
}

export default OrderItem;
