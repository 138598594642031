import React from 'react';
import EllipsisCell from '../../common/ellipsis_cell';
import NumberCell from '../../common/number_cell';

export default function PurchaseOrderRow({
  purchaseOrderItem, columnView,
}) {
  return (
    <div className="tr">
      { columnView.poNumber
        && (
          <EllipsisCell text={purchaseOrderItem.poNumber} />
        )
      }
      { columnView.raised
        && (
          <EllipsisCell text={purchaseOrderItem.raised} />
        )
      }
      { columnView.supplierName
        && (
          <EllipsisCell text={`${purchaseOrderItem.supplierName} (${purchaseOrderItem.supplierId})`} />
        )
      }
      { columnView.budgetName
        && (
          <EllipsisCell text={`${purchaseOrderItem.budgetCode || ''} ${purchaseOrderItem.budgetName || ''}`} />
        )
      }
      { columnView.categoryName
        && (
          <EllipsisCell text={`${purchaseOrderItem.categoryNumber || ''} ${purchaseOrderItem.categoryName || ''}`} />
        )
      }
      { columnView.number
        && (
          <EllipsisCell text={purchaseOrderItem.number} />
        )
      }
      { columnView.description
        && (
          <EllipsisCell text={purchaseOrderItem.description} />
        )
      }
      { columnView.quantity
        && (
          <NumberCell text={purchaseOrderItem.quantity} />
        )
      }
      { columnView.price
        && (
          <NumberCell text={purchaseOrderItem.price} />
        )
      }
      { columnView.unit
        && (
          <EllipsisCell text={purchaseOrderItem.unit} />
        )
      }
      { columnView.orderedValue
        && (
          <NumberCell text={purchaseOrderItem.orderedValue} />
        )
      }
      { columnView.currency
        && (
          <EllipsisCell className="text-right" text={purchaseOrderItem.currency} />
        )
      }
      { columnView.orderedCurrencyValue
        && (
          <NumberCell text={purchaseOrderItem.orderedCurrencyValue} />
        )
      }
      { columnView.receivedQuantity
        && (
          <NumberCell text={purchaseOrderItem.receivedQuantity} />
        )
      }
      { columnView.receivedValue
        && (
          <NumberCell text={purchaseOrderItem.receivedValue} />
        )
      }
      { columnView.state
        && (
          <a className="td has-status">
            <span className={`status capitalize ${purchaseOrderItem.state}`}>
              {I18n.t(`purchase_orders.statuses.${purchaseOrderItem.state}`)}
            </span>
          </a>
        )
      }
    </div>
  );
}
