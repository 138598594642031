import React from 'react';
import Table from '../shared/table';
import TableHead from './shared/table_head';
import PurchaseOrderRow from './purchase_orders/purchase_order_row';
import PurchaseOrderFilters from './shared/purchase_order_filters';
import MobileFilters from './shared/mobile_filters';
import PaginateWithDots from '../item_list/paginate_with_dots';
import AccountingsService from '../../services/accountings_service';
import PurchaseOrderAttach from './purchase_orders/purchase_order_attach';
import Modal from '../shared/modal';
import Loader from '../loader';
import * as swipe from '../shared/swipe';
import BulkActions from './purchase_orders/bulk_actions';
import PurchaseOrderMarkAsInvoicedModal from './purchase_orders/purchase_order_mark_as_invoiced';

class AccountingPurchaseOrders extends Table {
  constructor(props) {
    super(props);

    const urlFilters = this.urlParamsSetup();
    const completedStates = ['completed', 'archive', 'invoiced'];
    const page = urlFilters.page ? parseInt(urlFilters.page) : 1;

    this.filters = urlFilters.filters;
    delete this.filters.show_completed;
    this.order = urlFilters.order;

    this.state = {
      purchaseOrders: [],
      pages: 1,
      currentPage: page,
      filters: [],
      filterActions: [],
      showCompleted: this.filters.state ? this.filters.state.every((_val, i) => completedStates[i]) : false,
      filtersComponentKey: 1,
      attachPurchaseOrderId: null,
      openAttachPoSettings: null,
      recommended: false,
      allPurchaseOrdersInvoiced: false,
      attachPurchaseOrders: [],
      openBulkActions: false,
      openMarkedAsInvoiced: false,
    };
  }

  componentDidMount() {
    const { updateTotalCounts, updateTotalInvoicesCounts } = this.props;
    swipe.triggerSwipeEvent();
    this.onTableLoading();
    AccountingsService.purchaseOrders(window.location.search).then(({
      purchaseOrders,
      pages,
      filters,
      transactionsCount,
      purchaseOrdersCount,
      invoicesCount,
    }) => {
      this.setState({
        purchaseOrders,
        pages,
        filters,
        filterActions: filters ? Object.keys(filters) : [],
      });
      this.onTableLoad();
      updateTotalInvoicesCounts(invoicesCount, purchaseOrdersCount, transactionsCount);
      updateTotalCounts(transactionsCount, purchaseOrdersCount, invoicesCount);
    });
  }

  onFilter = ({ name, value }) => {
    const { showCompleted } = this.state;
    const stringValue = value.toString();

    if (showCompleted) {
      this.filters.state = name === 'state' ? [stringValue] : [];
    } else if (this.filters[name]) {
      this.filters[name].push(stringValue);
    } else {
      this.filters[name] = [stringValue];
    }

    this.filter({ showCompleted: false });
  }

  filterCompleted = () => {
    const { showCompleted } = this.state;

    if (showCompleted) {
      this.filters.state = [];
    } else {
      this.filters.state = ['completed', 'archive', 'invoiced'];
    }

    const uniqFilterKey = Math.floor(Math.random() * 1000);

    this.filter({ showCompleted: !showCompleted, filtersComponentKey: uniqFilterKey });
  }

  filter = (additionalState = {}, page = 1) => {
    const { updateTotalCounts, updateTotalInvoicesCounts } = this.props;
    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      page,
      order: this.order,
    };

    this.onTableLoading();

    AccountingsService.purchaseOrdersFilter(attributes).then(({
      purchaseOrders, pages, query, transactionsCount, purchaseOrdersCount, invoicesCount,
    }) => {
      window.history.pushState({}, 'Accounting', query);
      this.setState({
        purchaseOrders,
        pages,
        currentPage: 1,
        filtersComponentKey: Math.floor(Math.random() * 1000),
        openBulkActions: false,
        attachPurchaseOrders: [],
        allPurchaseOrdersInvoiced: false,
        ...additionalState,
      });
      this.onTableLoad();
      updateTotalCounts(transactionsCount, purchaseOrdersCount, invoicesCount);
      updateTotalInvoicesCounts(invoicesCount, purchaseOrdersCount, transactionsCount);
    });
  }

  headers = () => (
    [
      {
        name: 'po_number',
        translation: 'purchase_orders.po_number',
      },
      {
        name: 'date',
        translation: 'purchase_orders.date_raised',
      },
      {
        name: 'supplier',
        translation: 'purchase_orders.supplier',
      },
      {
        name: 'budget',
        translation: 'purchase_orders.budget_name',
      },
      {
        name: 'amount',
        class: 'number price',
        translation: 'purchase_orders.partials.invoices_body.po_value',
      },
      {
        name: 'not_invoiced',
        class: 'number price',
        translation: 'purchase_orders.partials.invoices_body.not_invoiced',
      },
      {
        name: 'invoiced',
        class: 'number price',
        translation: 'purchase_orders.partials.invoices_body.invoiced',
      },
      {
        name: 'committed',
        class: 'number price',
        translation: 'purchase_orders.partials.invoices_body.committed',
      },
      {
        name: 'invoices',
        class: 'center',
        translation: 'purchase_orders.partials.invoices_body.invoiced',
      },
      {
        name: 'received',
        class: 'center',
        translation: 'purchase_orders.received',
        sortable: false,
      },
      {
        name: 'status',
        class: 'has-status',
        translation: 'purchase_orders.status',
      },
      {
        name: 'raised_by',
        translation: 'purchase_orders.raised_by',
      },
      {
        name: 'comments',
        class: 'center',
        translation: 'purchase_orders.comments',
      },
      {
        name: 'actions',
      },
    ]
  )

  onToggleAttachPoSettings = (id, recommended) => {
    this.setState({
      attachPurchaseOrderId: id,
      openAttachPoSettings: !this.state.openAttachPoSettings,
      recommended,
    });
  }

  onBulkSelect = (selectedPurchaseOrder) => {
    const attachedPurchaseOrders = [];

    const purchaseOrders = this.state.purchaseOrders.map((purchaseOrder) => {
      if (purchaseOrder.id === selectedPurchaseOrder.id) {
        const selected = !purchaseOrder.selected;
        const newPurchaseOrder = purchaseOrder;
        newPurchaseOrder.selected = selected;
        if (selected) attachedPurchaseOrders.push(newPurchaseOrder);
        return newPurchaseOrder;
      }
      if (purchaseOrder.selected) attachedPurchaseOrders.push(purchaseOrder);
      return purchaseOrder;
    });

    const openBulkActions = attachedPurchaseOrders.length > 0;

    this.setState({
      openBulkActions,
      purchaseOrders,
      attachPurchaseOrders: attachedPurchaseOrders,
      allPurchaseOrdersInvoiced: false,
    });
  }

  onBulkSelectAll = () => {
    const { allPurchaseOrdersInvoiced, purchaseOrders } = this.state;
    const assignAll = !allPurchaseOrdersInvoiced;

    this.setState({
      openBulkActions: assignAll,
      purchaseOrders: purchaseOrders.map((invoice) => {
        const newInvoice = invoice;
        newInvoice.selected = assignAll;
        return newInvoice;
      }),
      attachPurchaseOrders: assignAll ? purchaseOrders : [],
      allPurchaseOrdersInvoiced: assignAll,
    });
  }

  onToggleBulkActions = () => {
    const { openBulkActions, purchaseOrders } = this.state;

    this.setState({
      openBulkActions: !openBulkActions,
      attachPurchaseOrders: [],
      allPurchaseOrdersInvoiced: false,
      purchaseOrders: purchaseOrders.map((purchaseOrder) => {
        const newPurchaseOrder = purchaseOrder;
        newPurchaseOrder.selected = false;
        return newPurchaseOrder;
      }),
    });
  }

  onToggleMarkAsInvoiced = () => {
    this.setState({
      openMarkedAsInvoiced: !this.state.openMarkedAsInvoiced,
    });
  }

  render() {
    const {
      purchaseOrders, pages, currentPage, filterActions, filters, filtersComponentKey, showCompleted,
      openAttachPoSettings, attachPurchaseOrderId, isLoading, recommended, attachPurchaseOrders, openBulkActions,
      openMarkedAsInvoiced, allPurchaseOrdersInvoiced,
    } = this.state;
    const {
      companyCurrency, standAlone, decimalMark, dateFormat,
    } = this.props;
    const dotsNumber = this.headers().length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        <div className="tab-pages tile-content">
          {openMarkedAsInvoiced
            ? (
              <PurchaseOrderMarkAsInvoicedModal
                onToggleMarkAsInvoiced={this.onToggleMarkAsInvoiced}
                filter={this.filter}
                purchaseOrders={attachPurchaseOrders}
              />
            ) : null}
          {openAttachPoSettings
            ? (
              <Modal>
                <PurchaseOrderAttach
                  onToggleAttachPoSettings={this.onToggleAttachPoSettings}
                  filter={this.filter}
                  purchaseOrderId={attachPurchaseOrderId}
                  currency={companyCurrency}
                  recommended={recommended}
                  standAlone={standAlone}
                  refreshPaymentPlan={() => {}}
                />
              </Modal>
            )
            : null}
          <div className="page active">
            {!openBulkActions
              && (
              <PurchaseOrderFilters
                onFilter={this.onFilter}
                onRemoveFilter={this.onRemoveFilter}
                onDateFilter={this.onDateFilter}
                onQueryFilter={this.onQueryFilter}
                currentFilters={this.filters}
                clearDate={this.clearDate}
                filters={filters}
                filterActions={filterActions}
                integrationRefesh={false}
                key={`availableFiltersPurchaseOrders${filtersComponentKey}`}
                openAutoMatchModal={this.openAutoMatchModal}
                baseExportPath="purchase_orders"
                dateFormat={dateFormat}
              />
              )
            }
            <div className="has-bulk">
              {openBulkActions
                ? (
                  <BulkActions
                    purchaseOrders={attachPurchaseOrders}
                    onToggleBulkActions={this.onToggleBulkActions}
                    onToggleMarkAsInvoiced={this.onToggleMarkAsInvoiced}
                  />
                ) : null}
              <div className={`table-fluid table-scrollable table-column-${dotsNumber}${htmlClasses}`}>
                <Loader />
                {this._renderInputs(dotsNumber)}
                <div className="table-header with-filters">
                  {filterActions.length > 0 ? (
                    <MobileFilters
                      onFilter={this.onFilter}
                      onRemoveFilter={this.onRemoveFilter}
                      onDateFilter={this.onDateFilter}
                      currentFilters={this.filters}
                      clearDate={this.clearDate}
                      filters={filters}
                      filterActions={filterActions}
                      filtersPillsKey={`availableMobileFilters${filtersComponentKey}`}
                    />
                  ) : null}
                  <div className="table-nav">
                    {this._renderLabels(dotsNumber)}
                    {this._renderDots(dotsNumber)}
                  </div>
                </div>
                <div className="table table-sortable">
                  <div className="colgroup">
                    {this._renderColls(dotsNumber)}
                  </div>
                  <TableHead
                    onOrder={this.onOrder}
                    order={this.order}
                    headers={this.headers()}
                    onBulkSelectAll={this.onBulkSelectAll}
                    allPurchaseOrdersInvoiced={allPurchaseOrdersInvoiced}
                  />
                  <div className="tbody">
                    {!isLoading && purchaseOrders.map(purchaseOrder => (
                      <PurchaseOrderRow
                        key={purchaseOrder.id}
                        purchaseOrder={purchaseOrder}
                        onToggleAttachPoSettings={this.onToggleAttachPoSettings}
                        ableToAttach={!showCompleted}
                        onBulkSelect={this.onBulkSelect}
                        onBulkRemove={this.onBulkRemove}
                        decimalMark={decimalMark}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer">
          {showCompleted
            ? <a className="link" onClick={this.filterCompleted}>{I18n.t('purchase_orders.hide_completed_po')}</a>
            : <a className="link" onClick={this.filterCompleted}>{I18n.t('purchase_orders.show_completed_po')}</a>
          }
          <PaginateWithDots onPageChange={this.onPageChange} numberPages={pages} selected={currentPage} />
        </div>
      </React.Fragment>
    );
  }
}

export default AccountingPurchaseOrders;
