import React, { useState } from 'react';
import InvitationModel from '../models/Invitation';
import Invitation from './registrations/companies/invitations/invitation';
import InvitationsService from '../services/invitations_service';
import logo from '../../assets/images/logo-small.png';

export default function Invitations() {
  const [invitations, setInvitations] = useState([new InvitationModel()]);

  const updateInvitations = (uuid, attributeName, attributeValue) => {
    const newInvitations = invitations.map((invitation) => {
      if (invitation.uuid == uuid) {
        const newInvitation = invitation;
        newInvitation[attributeName] = attributeValue;
        return newInvitation;
      }
      return invitation;
    });
    setInvitations(newInvitations);
  };

  const removeInvitation = (uuid) => {
    setInvitations(invitations.filter(invitation => invitation.uuid != uuid));
  };

  const companyId = URI(window.location.href).query(true).company_id;

  function sendInvitations() {
    InvitationsService.sendInvitations(companyId, { invitations })
      .then(({ errors }) => {
        const uuids = Object.keys(errors);
        if (uuids.length > 0) {
          uuids.forEach((uuid) => {
            updateInvitations(uuid, 'errors', errors[uuid]);
          });
        } else {
          window.location.href = '/companies';
        }
      });
  }

  return (
    <div className="content-holder">
      <div className="tile grid unauthorized registration invitations">
        <div className="cell-auto">
          <div className="icon-logo">
            <img src={logo} />
          </div>
        </div>
        <div className="cell-fill">
          <div className="tile-header no-line with-steps">
            <div className="head">
              <h1>{I18n.t('registrations.companies.invitations.header')}</h1>
              <p>{I18n.t('registrations.companies.invitations.step3')}</p>
            </div>
            <div className="steps">
              <div className="step done" />
              <div className="step done" />
              <div className="step current" />
            </div>
          </div>
          <div className="tile-content with-padding">
            <div className="row description">
              {I18n.t('registrations.companies.invitations.description')}
            </div>
            <div className="row table-head">
              {I18n.t('registrations.companies.invitations.email')}
            </div>
            {
              invitations.map(invitation => (
                <Invitation
                  key={invitation.uuid}
                  invitation={invitation}
                  updateInvitations={updateInvitations}
                  removeInvitation={removeInvitation}
                />
              ))
            }
            <div className="row new-invitation">
              <label className="link" onClick={() => setInvitations([...invitations, new InvitationModel()])}>
                +
                {I18n.t('registrations.companies.invitations.invite_user')}
              </label>
            </div>
            <div className="row">
              <div className="cell with-action">
                <div className="actions justify-between">
                  <a href={`/registrations/companies/${companyId}/edit`} className="button button-back button-l rounded">
                    {I18n.t('registrations.common.back')}
                  </a>
                  <button className="button button-primary button-l rounded" onClick={sendInvitations}>
                    {I18n.t('registrations.common.next')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
