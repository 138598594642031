import React, { useState } from 'react';

export default function Invitation({ invitation, updateInvitations, removeInvitation }) {
  const { uuid, errors } = invitation;
  const [email, setEmail] = useState(invitation.email);

  const showErrors = errors.length > 0;

  return (
    <div>
      <div className={`row justify-between email-row ${showErrors ? 'error' : ''}`}>
        <input
          value={email}
          name={`email-${uuid}`}
          placeholder={I18n.t('registrations.companies.invitations.enter_email')}
          onChange={({ target: { value } }) => setEmail(value)}
          className="input-r"
          onBlur={() => updateInvitations(uuid, 'email', email)}
        />
        <i className="icon-close" onClick={() => removeInvitation(uuid)} />
      </div>
      {
        errors && (
          <span className="error">{errors}</span>
        )
      }
    </div>
  );
}
